<template>
  <div class="important-page-c display--flex fd--column" v-if="moduleData">
    <!-- 操作按钮 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="moduleData.buttonlist && moduleData.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="moduleData.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
				
      >
        <el-button
          v-for="(item, index) in moduleData.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>
    <!-- 树形结构 -->
    <el-tree
      ref="moduleTree"
      :data="moduleData.dhtmlxtree.item"
      :props="defaultProps"
      node-key="id"
      show-checkbox
      @check="handleCheck"
      check-strictly
    />

    <!-- 增加顶级模块 -->
    <el-dialog
      class="btn-2b5a95"
      width="500px"
			v-dialogDrag
			:close-on-click-modal='false'
      title="增加顶级模块"
      :visible.sync="showMaxModal"
      v-if="showMaxModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
        label-width="100px"
      >
				<el-form-item label="父亲节点名称：" prop="parent">
				  <el-input v-model="dynamicValidateForm.parent" disabled/>
				</el-form-item>
				<el-form-item label="模块深度：" prop="deepth">
				  <el-input v-model="dynamicValidateForm.deepth" disabled />
				</el-form-item>
				<el-form-item label="模块编号：" prop="code">
				  <el-input v-model.trim="dynamicValidateForm.code" clearable/>
				</el-form-item>
        <el-form-item label="模块名称：" prop="name">
          <el-input v-model.trim="dynamicValidateForm.name" clearable/>
        </el-form-item>
        <el-form-item label="路径名称：" prop="url">
          <el-input v-model.trim="dynamicValidateForm.url" clearable/>
        </el-form-item>
				<el-form-item label="按钮名称：" prop="buttonname">
				  <el-input v-model.trim="dynamicValidateForm.buttonname" clearable/>
				</el-form-item>
				<el-form-item label="排序权重：" prop="rank">
				  <el-input v-model.trim="dynamicValidateForm.rank" clearable/>
				</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitMaxModuleForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 增加下级模块 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
      width="500px"
			v-dialogDrag
      title="增加下级模块"
      :visible.sync="showNextModal"
      v-if="showNextModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="父亲节点：" prop="parent">
          <el-input v-model="dynamicValidateForm.parent" disabled />
        </el-form-item>
        <el-form-item label="模块深度：" prop="deepth">
          <el-input v-model="dynamicValidateForm.deepth" disabled />
        </el-form-item>
				<el-form-item label="模块编号：" prop="code">
				  <el-input 
						v-model.trim="dynamicValidateForm.code"
					clearable/>
				</el-form-item>
        <el-form-item label="模块名称：" prop="name">
          <el-input v-model.trim="dynamicValidateForm.name" clearable/>
					<el-select 
						v-model="dynamicValidateForm.name"
						placeholder="请选择"
						clearable
						@change="onChangeProper"
						>
					 <el-option
							v-for="item in propSelect"
							:key="item.key"
							:value="item.key"
							:label="item.val"
						></el-option>
					</el-select>
				</el-form-item> 
        <el-form-item label="路径名称：" prop="url">
          <el-input v-model.trim="dynamicValidateForm.url" clearable/>
        </el-form-item>
        <el-form-item label="按钮名称：" prop="buttonname">
          <el-input v-model.trim="dynamicValidateForm.buttonname" clearable/>
        </el-form-item>
        <el-form-item label="排序值：" prop="rank">
          <el-input v-model.trim="dynamicValidateForm.rank"  clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitNextModuleForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 修改当前模块 -->
    <el-dialog
      class="btn-2b5a95"
      width="500px"
			v-dialogDrag
      title="修改当前模块"
			:close-on-click-modal='false'
      :visible.sync="showCurrentModal"
      v-if="showCurrentModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="父亲节点：" prop="parent">
          <el-input v-model="dynamicValidateForm.parent" disabled />
        </el-form-item>
        <el-form-item label="模块深度：" prop="deepth">
          <el-input v-model.trim="dynamicValidateForm.deepth" clearable/>
        </el-form-item>
       <el-form-item label="模块名称：" prop="name">
         <el-input v-model.trim="dynamicValidateForm.name" clearable/>
       	<el-select 
       		v-model="dynamicValidateForm.name"
       		placeholder="请选择"
       		clearable
       		@change="onChangeProper"
       		>
       	 <el-option
       			v-for="item in propSelect"
       			:key="item.key"
       			:value="item.key"
       			:label="item.val"
       		></el-option>
       	</el-select>
       </el-form-item> 
        <el-form-item label="路径名称：" prop="url">
          <el-input v-model.trim="dynamicValidateForm.url"  clearable/>
        </el-form-item>
        <el-form-item label="按钮名称：" prop="buttonname">
          <el-input v-model.trim="dynamicValidateForm.buttonname" clearable/>
        </el-form-item>
        <el-form-item label="排序值：" prop="rank">
          <el-input v-model.trim="dynamicValidateForm.rank"  clearable/>
        </el-form-item>
				<el-form-item label="是否可用：" prop="enableflag">
				  <el-input v-model.trim="dynamicValidateForm.enableflag"  clearable/>
				</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitModuleForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 导出 -->
    <Export
      :exportTitle="'模块管理'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>
  </div>
</template>

<script>
import {
	_propSelects
} from "@/assets/js/filedValueFlag";	
import { part } from "@/utils/mixins";
import { myRequest } from "@/request/index";
import {
  module_treedata,
  module_input,
  module_save,
  module_delete,
  module_enable,
  module_export,
  module_exportexcel,
  module_exportsave,
} from "@/request/api/public";
import Export from "@/components/Export/index.vue";
import { mapActions } from "vuex";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
export default {
  mixins: [part],
  inject: ["reload"],
  name: "module",
  components: {
    Export,
  },
  props: {
    exportUrl: {
      default: () => module_export,
    },
    exportexcelUrl: {
      default: () => module_exportexcel,
    },
    exportsaveUrl: {
      default: () => module_exportsave,
    },
  },
  data() {
    return {
			propSelect: _propSelects,
      defaultProps: {
        children: "item",
        label: "text",
      },
      moduleData: null, //模块数据
      dynamicValidateForm: {}, //表单
      searchForm: {},
      rules: {
        name: [{ trigger: "blur", message: "必填字段", required: true }],
				code: [{ trigger: "blur", message: "必填字段", required: true }],
				buttonname: [{ trigger: "blur", message: "必填字段", required: true }], 
				rank: [{ trigger: "blur", message: "必填字段", required: true }], 
				url: [{ trigger: "blur", message: "必填字段", required: true }], 
      },
      nodeItem: {}, //选中节点项
      showMaxModal: false, //顶级模块
      showNextModal: false, //下级模块
      showCurrentModal: false, //当前模块
      selectCheckNodeIds: [], //选中的节点
      transferDialog: false, //导出框
    };
  },
  created() {
    this.getModuleTreeData();
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
		//改变选项框中的数据
		onChangeProper(val) {
			var _this = this
			let obj = {};
			obj = this.propSelect.find((item) => {
				return item.key === val;
			})
			this.dynamicValidateForm.name = obj.val;
			this.dynamicValidateForm.buttonname = obj.key;
		},
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
    // 选中复选节点 click
    handleCheck(item, datas) {
      this.selectCheckNodeIds = datas.checkedKeys;
      this.nodeItem = item;
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //获取数据结构 api
    getModuleTreeData() {
      myRequest({
        url: module_treedata,
      }).then((res) => {
        if (res.data.code === "200") {
          this.moduleData = res.data.data;
        }
      });
    },
    //增加，修改路由 api
    inputModule(modal) {
      myRequest({
        url: module_input,
        data: this.dynamicValidateForm,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.tmodule;
          this.dynamicValidateForm.deepth = res.data.data.deepth;
          this.dynamicValidateForm.parent = res.data.data.parent;
          this.dynamicValidateForm.parentid = res.data.data.parentid;
					this.dynamicValidateForm.code = res.data.data.code;
          this.dynamicValidateForm.id = 0;
          this[modal] = true;
        }
      });
    },
    //增加顶级模块 click
    btnAdd0() {
      this.dynamicValidateForm = {
        id: 0,
        parentid: 0,
      };
      this.inputMaxModule();
    },
    //增加顶级模块路由 api
    inputMaxModule() {
      myRequest({
        url: module_input,
        data: this.dynamicValidateForm,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.tmodule;
          this.dynamicValidateForm.id = 0;
          this.dynamicValidateForm.parentid = 0;
					this.dynamicValidateForm.deepth = res.data.data.deepth;
					this.dynamicValidateForm.parent = res.data.data.parent;
					this.dynamicValidateForm.code = res.data.data.code;
          this.showMaxModal = true;
        }
      });
    },
    //增加顶级模块 - 提交 api
    submitMaxModuleForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: module_save,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
              modal: "showMaxModal",
            }
          );
        }
      });
    },
    //增加下级模块 click
    btnAdd1() {
      selectCheck(
        this.$refs.moduleTree.getCheckedNodes(),
        "操作",
        false,
        () => {
          this.dynamicValidateForm = {
            id: 0,
						parentid: this.$refs.moduleTree.getCheckedNodes()[0].id,
          };
          this.inputModule("showNextModal");
        }
      );
    },
    //增加下级模块 - 提交 click
    submitNextModuleForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: module_save,
              data:{
								parentid:this.$refs.moduleTree.getCheckedNodes()[0].id,
								name: this.dynamicValidateForm.name,
								buttonname: this.dynamicValidateForm.buttonname,
								rank: this.dynamicValidateForm.rank,
								deepth:  this.dynamicValidateForm.deepth,
								code: this.dynamicValidateForm.code,
								url: this.dynamicValidateForm.url
							},
            },
            {
              that: this,
              modal: "showNextModal",
            }
          );
        }
      });
    },
    //修改 click
    btnEdit() {
      selectCheck(this.selectCheckNodeIds, "操作", false, () => {
        this.dynamicValidateForm = {
          parentid: this.nodeItem.parentId || 0,
					id: this.nodeItem.id,
        };
        this.inputModule("showCurrentModal");
      });
    },
    //修改当前模块 - 提交 click
    submitModuleForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: module_save,
              data: {
								id: this.nodeItem.id,
								name: this.dynamicValidateForm.name,
								url: this.dynamicValidateForm.url,
								enableflag: this.dynamicValidateForm.enableflag,
								code: this.dynamicValidateForm.code,
								deepth: this.dynamicValidateForm.deepth,
								rank: this.dynamicValidateForm.rank,
								buttonname: this.dynamicValidateForm.buttonname
							}
            },
            {
              that: this,
              modal: "showCurrentModal",
            }
          );
        }
      });
    },
    //删除 click
    btnDel() {
      selectCheck(this.selectCheckNodeIds, "操作", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delModuleItem();
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //删除 api
    delModuleItem() {
      myRequest({
        method: "post",
        url: module_delete,
        data: this.$qs.stringify(
          {
            ids: this.selectCheckNodeIds,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      });
    },
    //启用 click
    btnEnable1() {
      selectCheck(this.selectCheckNodeIds, "操作", true, () => {
        confirmCallBack({
          title: "提示",
          content: "请确认变更的操作",
          success: () => {
            this.moduleEnable(1);
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //禁用 click
    btnEnable0() {
      selectCheck(this.selectCheckNodeIds, "操作", true, () => {
        confirmCallBack({
          title: "提示",
          content: "请确认变更的操作",
          success: () => {
            this.moduleEnable(0);
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //启用/禁用 api
    moduleEnable(enable) {
      myRequest({
        method: "post",
        url: module_enable,
        data: this.$qs.stringify(
          {
            ids: this.selectCheckNodeIds,
            enable: enable,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      });
    },
    //排序 click
    btnSort() {},
    //看流水号 click
    btnAlertid() {
      selectCheck(this.selectCheckNodeIds, "操作", false, () => {
        alert(this.nodeItem.id+'-'+this.nodeItem.text);
      });
    },
    //刷新 click
    btnReload() {
      this.getModuleTreeData();
    },
  },
};
</script>

<style>
</style>
